<template>
    <div class="at-input" :class="{focus: foc || highlight }" :style="{width}">
        <span class="unit" v-if="unit">{{ unit }}</span>
        <div class="input-wrapper">
            <input v-model="val"
                   :maxLength="maxLength"
                   :placeholder="placeholder"
                   @focus=" foc = true " @blur=" foc = false "
                   @change="valChanged"
                   @keyup="valChanged"
                   :disabled="disabled"
                   @input="input"
                   ref="input"
                   class="input"/>
        </div>
        <span class="unit" v-if="addonAfter">{{ addonAfter }}</span>
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "AtInput",
        model: {
            prop: 'value',
            event: 'change',
        },
        props: {
            addonAfter: String,
            width: String,
            placeholder: String,
            unit: String,
            highlight: Boolean,
            value: [String, Number],
            maxLength: [String, Number],
            disabled: Boolean,
        },
        watch: {
            value() {
                this.val = this.value
            }
        },
        data() {
            return {
                foc: false,
                val: this.value,
            }
        },
        methods: {
            focus(){
                this.$refs['input'].focus()
            },
            valChanged(e) {
                this.$emit('change', e.target.value)
            },
            input(e){
                this.$emit('input',e)
            }
        }
    }
</script>

<style lang="less" scoped>
    .at-input {
        width: 152px;
        height: 40px;
        display: flex;
        border: 1px solid #EEEEEE;
        flex-direction: row;
        overflow: hidden;
        transition: all .3s;
        position: relative;

        &.focus {
            border-color: #333333;

            .unit {
                background-color: #333333;
                color: #FFFFFF;
            }
        }
    }

    .input-wrapper {
        flex: 1;
    }

    .input {
        outline: none;
        border: none;
        width: 100%;
        line-height: 40px;
        text-align: left;
        padding: 10px 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        height: 40px;
    }

    .unit {
        display: block;
        //width: 40px;
        height: 40px;
        padding: 0 8px;
        background: #EEEEEE;
        line-height: 40px;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        text-align: center;
    }
</style>