<template>
    <div class="ast-pay-box">
        <div class="ast-pay-box-title">
            <div>
                {{ title }}
                <span class="ast-pay-box-sub-title" v-if="subTitle">{{ subTitle }}</span>
            </div>
            <slot name="extra" />
        </div>
        <div class="ast-pay-body">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: "PayBox",
        props: {
            title: String,
            subTitle: String,
        }
    }
</script>

<style lang="less" scoped>
    .ast-pay-body {
        padding: 28px 24px;
    }

    .ast-pay-box-title {
        border-bottom: 1px solid #EEEEEE;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        padding: 24px 24px 16px;
        position: relative;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &:before {
            content: ' ';
            width: 2px;
            height: 14px;
            background: #333333;
            position: absolute;
            left: 1px;
        }
    }

    .ast-pay-box-sub-title {
        margin-left: 8px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
    }

    .ast-pay-box {
        border: 1px solid #EEEEEE;
        margin-bottom: 24px;
    }
</style>